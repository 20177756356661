interface StoryCardProps {
  title: string;
  imageSrc: string;
  width?: string;
  height?: string;
}

const StoryCard = ({ title, imageSrc, width, height }: StoryCardProps) => {
  return (
    <div
      style={{
        position: "relative",
        width: width || "300px",
        height: height || "300px",
        overflow: "hidden",
        borderRadius: "4px",
      }}
    >
      <img
        src={imageSrc}
        alt="Story Card"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: "0",
          left: "0",
          right: "0",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          color: "#fff",
          padding: "10px",
          textAlign: "center",
        }}
      >
        <h3 style={{ margin: "0" }}>{title}</h3>
      </div>
    </div>
  );
};

export default StoryCard;
