import { useRouteError, useNavigate } from "react-router-dom";
import styles from "./Error.module.css";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div id="error-page" className={styles.errorContainer}>
      <h1 className={styles.errorHeading}>Oops!</h1>
      <p className={styles.errorMessage}>
        Sorry, an unexpected error has occurred.
      </p>
      <p className={styles.errorDetails}>
        <i>{error.statusText || error.message}</i>
      </p>
      <button className={styles.goBackButton} onClick={goBack}>
        Go Back
      </button>
    </div>
  );
}
