import { useState } from "react";
import style from "./Carousel.module.css";

interface CarouselProps {
  images: string[];
}

const Carousel = ({ images }: CarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  return (
    <div className={style.carousel}>
      <img
        src={images[activeIndex]}
        alt={`Slide ${activeIndex}`}
        className={style["carousel__img"]}
      />
      <button
        onClick={prevSlide}
        className={`${style["carousel__btn"]} ${style["carousel__btn--prev"]}`}
      >
        &lt;
      </button>
      <span
        style={{
          position: "absolute",
          top: "20%",
          left: "20%",
          fontSize: "96px",
          fontWeight: "bold",
          color: "white",
          maxWidth: "500px",
          justifyContent: "center",
          textShadow: "12px 12px 14px rgba(0,0,0,0.5)", // Text shadow
        }}
      >
        БАЛКАН БЕЗ ГРАНИЦИ
      </span>

      <button
        onClick={nextSlide}
        className={`${style["carousel__btn"]} ${style["carousel__btn--next"]}`}
      >
        &gt;
      </button>
    </div>
  );
};
export default Carousel;
