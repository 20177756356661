import NavigationBar from "../components/navbar/Navbar";

const Kontakt = () => {
  return (
    <>
      <NavigationBar />
      <div
        style={{
          width: "100%",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFA100",
            color: "white",
            padding: "100px",
            borderRadius: "8px",
            textAlign: "center",
          }}
        >
          <h2>Информации за контакт</h2>
          <p>Email: balkanbezgranici@gmail.com</p>
          <p>Телефон: +38711223344</p>
        </div>
      </div>
    </>
  );
};

export default Kontakt;
