import React, { useState } from "react";
import { GrInProgress } from "react-icons/gr";

const MaintenanceNotification = () => {
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
  };

  return showModal ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "50px",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <GrInProgress color="blue" size={50} />
        <p style={{ fontSize: "20px", lineHeight: "2rem" }}>
          Ова веб страница е во изработка. <br />
          Пробајте повторно подоцна.
        </p>
        <button
          onClick={closeModal}
          style={{
            padding: "10px 20px",
            backgroundColor: "blue",
            borderRadius: "4px",
            border: "none",
            color: "white",
            cursor: "pointer",
          }}
        >
          Во ред
        </button>
      </div>
    </div>
  ) : null;
};

export default MaintenanceNotification;
