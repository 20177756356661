import StoryCard from "../components/card/StoryCard";
import Footer from "../components/footer/Footer";
import NavigationBar from "../components/navbar/Navbar";

//https://c8.alamy.com/comp/2B7PAE8/the-stone-bridge-of-vigla-or-balkan-between-the-villages-of-votonosi-and-anthohori-near-metsovo-in-ioannina-prefecture-epirus-region-greece-2B7PAE8.jpg
const LovRibolov = () => {
  return (
    <>
      <NavigationBar />
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              position: "absolute",
              top: "30%",
              left: "10%",
              fontSize: "56px",
              fontWeight: "bold",
              maxWidth: "10px",
              color: "white",
            }}
          >
            Лов и риболов
          </span>
          <img
            height={700}
            width="100%"
            src="https://images.alphacoders.com/276/276143.jpg"
            alt="Lov Ribolov"
            style={{ objectFit: "cover" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "20px 200px",
            textAlign: "center",
            gap: "20px",
          }}
        >
          <p>
            Ловењето или како ловство или лов е дејност чија основна цел е
            одгледување, размножување, заштита, ловење и користење на дивечот.
            Основна цел на активностите од овој домен е формирање и трајно
            одржување стабилни популации дивеч, со оптимална полова и возрасна
            структура за континуирана репродукција на здрави и квалитетни единки
            со високи трофејни вредности. Како стопанска дејност ѝ се придава
            посебна важност, особено од аспект на заштитата на природата.
          </p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              justifyContent: "space-evenly",
              justifyItems: "center",
              alignContent: "space-evenly",
              alignItems: "center",
              rowGap: "50px",
            }}
          >
            <StoryCard
              title="Лов на елени"
              imageSrc="https://c4.wallpaperflare.com/wallpaper/279/1003/787/deer-best-for-desktop-background-wallpaper-preview.jpg"
            />
            <StoryCard
              title="Рибарење"
              imageSrc="https://hellscanyon.tours/wp-content/uploads/2023/01/RiverAdventuresInc-214207-Pieces-Fishing-Equipment-blogbanner1-1080x675.jpg"
            />
            <StoryCard
              title="Лов со лак и стрела"
              imageSrc="https://wvdnr.gov/wp-content/uploads/2021/02/DeerHunting-9747-scaled.jpg"
            />
          </div>
          <div
            style={{
              margin: "50px 0px",
              borderRadius: "4px",
              padding: "50px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            <h1>Најнови вести</h1>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                justifyContent: "space-evenly",
                justifyItems: "center",
                alignContent: "space-evenly",
                alignItems: "center",
                rowGap: "50px",
              }}
            >
              <StoryCard
                title="Лов на елени"
                imageSrc="https://c4.wallpaperflare.com/wallpaper/279/1003/787/deer-best-for-desktop-background-wallpaper-preview.jpg"
              />
              <StoryCard
                title="Рибарење"
                imageSrc="https://hellscanyon.tours/wp-content/uploads/2023/01/RiverAdventuresInc-214207-Pieces-Fishing-Equipment-blogbanner1-1080x675.jpg"
              />
              <StoryCard
                title="Лов со лак и стрела"
                imageSrc="https://wvdnr.gov/wp-content/uploads/2021/02/DeerHunting-9747-scaled.jpg"
              />
              <StoryCard
                title="Лов на елени"
                imageSrc="https://c4.wallpaperflare.com/wallpaper/279/1003/787/deer-best-for-desktop-background-wallpaper-preview.jpg"
              />
              <StoryCard
                title="Рибарење"
                imageSrc="https://hellscanyon.tours/wp-content/uploads/2023/01/RiverAdventuresInc-214207-Pieces-Fishing-Equipment-blogbanner1-1080x675.jpg"
              />
              <StoryCard
                title="Лов со лак и стрела"
                imageSrc="https://wvdnr.gov/wp-content/uploads/2021/02/DeerHunting-9747-scaled.jpg"
              />
              <StoryCard
                title="Лов на елени"
                imageSrc="https://c4.wallpaperflare.com/wallpaper/279/1003/787/deer-best-for-desktop-background-wallpaper-preview.jpg"
              />
              <StoryCard
                title="Рибарење"
                imageSrc="https://hellscanyon.tours/wp-content/uploads/2023/01/RiverAdventuresInc-214207-Pieces-Fishing-Equipment-blogbanner1-1080x675.jpg"
              />
              <StoryCard
                title="Лов со лак и стрела"
                imageSrc="https://wvdnr.gov/wp-content/uploads/2021/02/DeerHunting-9747-scaled.jpg"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LovRibolov;
