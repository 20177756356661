import { useEffect, useState } from "react";
import VerticalCard from "../components/card/VerticalCard";
import Carousel from "../components/carousel/Carousel";
import Footer from "../components/footer/Footer";
import NavigationBar from "../components/navbar/Navbar";
import MaintenanceNotification from "./MaintenenceNotification";

const Doma = () => {
  const images = [
    "./images/ohrid.jpg",
    "./images/matka.jpg",
    "./images/korab.jpg",
  ];

  const [maintenancePopupVisible] = useState(true);

  return (
    <div className="App">
      <NavigationBar />
      <Carousel images={images} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px 200px",
          textAlign: "center",
          gap: "20px",
        }}
      >
        <p>
          Балкан без граници е вашето место за истражување на дивата природа и
          спортските предизвици на Балканскиот полуостров. Откријте
          неверојатните приказни и искуства од лов и риболов, како и од други
          природни активности, што го прават Балканот непроценлив ресурс за
          адвентури и забава. Добредојдени во нашиот свет, каде природата не
          познава граници!
        </p>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          justifyContent: "space-evenly",
          justifyItems: "center",
          alignContent: "space-evenly",
          alignItems: "center",
          rowGap: "50px",
          padding: "50px 0px",
        }}
      >
        <VerticalCard
          image="./images/korab.jpg"
          title="Врвот Кораб"
          description="Кораб — планина во Западна Македонија, на границата со Албанија."
        />
        <VerticalCard
          image="./images/matka.jpg"
          title="Заливот Матка"
          description="Матка — кањон на реката Треска, десна притока на Вардар, зафаќа површина од околу 5.000 хектари и се наоѓа на 17 километри југозападно од Скопје."
        />
        <VerticalCard
          image="./images/ohrid.jpg"
          title="Свети Наум Охридски"
          description="Св. Наум — манастир изграден на висока карпа, над самото Охридско Езеро, на неговата најјужна точка, во една месност со ретка природна убавина."
        />
        <VerticalCard
          image="https://kanal5.com.mk/uploads/thumbs/389803.jpg"
          title="Македонска народна носија"
          description="Народните носии во Македонија имаат многувековна традиција. Тие се дел од материјалната култура на македонскиот народ и претставуваат значајна гранка од народното уметничко творештво."
        />
        <VerticalCard
          image="https://upload.wikimedia.org/wikipedia/commons/f/fe/%D0%9C%D0%B0%D0%BA%D0%B5%D0%B4%D0%BE%D0%BD%D0%B8%D1%83%D0%BC_-_%D0%9A%D1%80%D1%83%D1%88%D0%B5%D0%B2%D0%BE.jpg"
          title="Споменикот Илинден во Крушево"
          description="„Илинден“ или Македониум — споменички комплекс во Крушево."
        />
      </div>
      <Footer />
      {maintenancePopupVisible && <MaintenanceNotification />}
    </div>
  );
};

export default Doma;
