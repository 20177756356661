import React from "react";
import styles from "./Navbar.module.css";

const NavigationBar: React.FC = () => {
  return (
    <nav className={styles.navbar}>
      <ul className={styles.navMenu}>
        <li className={styles.navItem}>
          <a href="/" className={styles.navLink}>
            <img height={22} width={22} src="./BLKN.svg" alt="Page logo" />
          </a>
        </li>
        <li className={styles.navItem}>
          <a href="/sport" className={styles.navLink}>
            Спорт
          </a>
        </li>
        <li className={styles.navItem}>
          <a href="/lov-i-ribolov" className={styles.navLink}>
            Лов и риболов
          </a>
        </li>
        <li className={styles.navItem}>
          <a href="/kulturno-nasledstvo" className={styles.navLink}>
            Културно наследство на балканот
          </a>
        </li>
        <li className={styles.navItem}>
          <a href="/zelena-ekologija" className={styles.navLink}>
            Зелена екологија
          </a>
        </li>
        <li className={styles.navItem}>
          <a href="/balkanski-folklor" className={styles.navLink}>
            Балкански фолклор
          </a>
        </li>
        <li className={styles.navItem}>
          <a href="/otvoren-balkan" className={styles.navLink}>
            Отворен балкан
          </a>
        </li>
        <li className={styles.navItem}>
          <a href="/kontakt" className={styles.navLink}>
            Контакт
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationBar;
