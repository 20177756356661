import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./components/error/Error";
import Sport from "./pages/Sport";
import LovRibolov from "./pages/LovRibolov";
import KulturnoNasledstvo from "./pages/KulturnoNasledstvo";
import ZelenaEkologija from "./pages/ZelenaEkologija";
import OtvorenBalkan from "./pages/OtvorenBalkan";
import BalkanskiFolklor from "./pages/BalkanskiFolklor";
import Doma from "./pages/Doma";
import Kontakt from "./pages/Kontakt";
import Fudbal from "./pages/Fudbal";
import MaintenanceNotification from "./pages/MaintenenceNotification";

console.log(
  "process.env.REACT_APP_UNDER_MAINTENANCE",
  process.env.REACT_APP_UNDER_MAINTENANCE
);

const router = createBrowserRouter([
  {
    path: "/",
    element:
      process.env.REACT_APP_UNDER_MAINTENANCE === "true" ? (
        <MaintenanceNotification />
      ) : (
        <Doma />
      ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/sport",
    element: <Sport />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sport/fudbal/:id",
    element: <Fudbal />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/lov-i-ribolov",
    element: <LovRibolov />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/kulturno-nasledstvo",
    element: <KulturnoNasledstvo />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/zelena-ekologija",
    element: <ZelenaEkologija />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/balkanski-folklor",
    element: <BalkanskiFolklor />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/otvoren-balkan",
    element: <OtvorenBalkan />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
