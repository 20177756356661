import Footer from "../components/footer/Footer";
import NavigationBar from "../components/navbar/Navbar";

const Fudbal = () => {
  return (
    <>
      <NavigationBar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Lionel Messi leads Argentina to win World Cup</h1>
        <img
          width={700}
          height={500}
          src="https://cdn.britannica.com/35/238335-050-2CB2EB8A/Lionel-Messi-Argentina-Netherlands-World-Cup-Qatar-2022.jpg"
          alt="Lionel Messi"
          style={{
            borderRadius: "4px",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Set flex direction to column
            maxWidth: "700px",
            padding: "30px 0px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              lineHeight: "2",
            }}
          >
            LUSAIL, Qatar (AP) — Lionel Messi, wearing a black Qatari robe over
            his blue-and-white Argentina shirt, kissed the World Cup, shuffled
            toward his teammates and hoisted the golden trophy high in the air.
            It was an iconic sight that finally — definitively — places the
            soccer superstar in the pantheon of the game’s greatest players.
            Messi’s once-in-a-generation career is complete: He is a World Cup
            champion. In probably the wildest final in the tournament’s 92-year
            history, Argentina won its third World Cup title by beating France
            4-2 in a penalty shootout after a 3-3 draw featuring two goals from
            the 35-year-old Messi and a hat trick by his heir apparent, France
            forward Kylian Mbappé. “It’s just crazy that it became a reality
            this way,” Messi said. “I craved for this so much. I knew God would
            bring this gift to me. I had the feeling that this (World Cup) was
            the one.”
          </p>
        </div>
      </div>
    </>
  );
};

export default Fudbal;
