interface Links {
  link: string;
  menu: string;
}

interface SidebarProps {
  items: Links[];
}

const Sidebar = ({ items }: SidebarProps) => {
  return (
    <div
      style={{
        display: "flex",
        width: "300px",
        flexDirection: "column",
        gap: "20px",
        backgroundColor: "#FFA100",
        color: "white",
        fontSize: "16px",
        fontWeight: "bold",
        padding: "200px 20px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {items.map((item) => (
        <a style={{ textDecoration: "none", color: "white" }} href={item.link}>
          {item.menu}
        </a>
      ))}
    </div>
  );
};

export default Sidebar;
