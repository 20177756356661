import { IoLogoFacebook } from "react-icons/io5";
import { FaYoutube, FaInstagram } from "react-icons/fa";

import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.left}>
        <img src="./BLKN.svg" alt="Logo" className={styles.logo} />
      </div>
      <div className={styles.middle}>Балкан без граници &copy; 2024</div>
      <div className={styles.right}>
        <a href="#" className={styles.socialLink}>
          <IoLogoFacebook />
        </a>
        <a href="#" className={styles.socialLink}>
          <FaYoutube />
        </a>
        <a href="#" className={styles.socialLink}>
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
