import NavigationBar from "../components/navbar/Navbar";
import Sidebar from "../components/sidebar/Sidebar";
import StoryCard from "../components/card/StoryCard";

const Sport = () => {
  const sidebarItems = [
    {
      link: "/sport/fudbal",
      menu: "Фудбал",
    },
    {
      link: "/sport/kosarka",
      menu: "Кошарка",
    },
    {
      link: "/sport/rakomet",
      menu: "Ракомет",
    },
    {
      link: "/sport/odbojka",
      menu: "Одбојка",
    },
    {
      link: "/sport/tenis",
      menu: "Тенис",
    },
    {
      link: "/sport/skijanje",
      menu: "Скијање",
    },
    {
      link: "/sport/moto-sport",
      menu: "Мото спорт",
    },
    {
      link: "/sport/velosipedizam",
      menu: "Велосипедизам",
    },
    {
      link: "/sport/borecki-vestini",
      menu: "Боречки вештини",
    },
    {
      link: "/sport/atletika",
      menu: "Атлетика",
    },
  ];

  return (
    <>
      <NavigationBar />
      <div style={{ display: "flex" }}>
        <Sidebar items={sidebarItems} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            padding: "50px",
            gap: "50px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 10%",
                fontSize: "48px",
                color: "#ffcc00",
                fontWeight: "bold",
              }}
            >
              <span>Најнови вести во светот на спортот</span>
            </div>
            <div>
              <StoryCard
                width="500px"
                height="300px"
                title="Lionel Messi leads Argentina to win World Cup"
                imageSrc="https://cdn.britannica.com/35/238335-050-2CB2EB8A/Lionel-Messi-Argentina-Netherlands-World-Cup-Qatar-2022.jpg"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <StoryCard
              title="Champions league starting"
              imageSrc="https://www.arsenal.com/sites/default/files/styles/large_16x9/public/gun__1381310671_uefa_champions_league.jpg?auto=webp&itok=M5ibuVZR"
            />
            <StoryCard
              title="Novak Djokovic Grand Slams"
              imageSrc="https://cdn.britannica.com/78/249578-050-01D46C9B/Novak-Djokovic-Serbia-US-Open-2023.jpg"
            />
            <StoryCard
              title="Lewis Hamiltion about Mercedes"
              imageSrc="https://e0.365dm.com/24/03/768x432/skysports-lewis-hamilton-mercedes_6489616.jpg?20240313221424"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sport;
