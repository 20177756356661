import styles from "./VerticalCard.module.css";

interface VerticalCardProps {
  image: string;
  title: string;
  description: string;
}

const VerticalCard = ({ image, title, description }: VerticalCardProps) => {
  return (
    <div className={styles.verticalCard}>
      <img src={image} alt={title} className={styles.cardImage} />
      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{title}</h3>
        <p className={styles.cardDescription}>{description}</p>
      </div>
      <button className={styles.button}>Погледни</button>
    </div>
  );
};

export default VerticalCard;
